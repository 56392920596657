import { StoreService } from '@/store';
import { initTwig } from '@webprojects/ui-pattern-library';

/**
 * Flat component configuration map
 * Each entry contains a selector and import function
 */
const MOUNTQUEUE = [
  {
    selector: '[data-bu-stage-slider]',
    import: () => import('@/components/templates/stage-slider/index.js')
  },
  {
    selector: '[data-bu-content-slider]',
    import: () => import('@/components/templates/content-slider/index.js')
  },
  {
    selector: '[data-bu-header]',
    import: () => import('@/components/templates/header/index.js')
  },
  {
    selector: '[data-bu-maintenance-warning]',
    import: () => import('@/components/templates/maintenance-warning/index.js')
  },
  {
    selector: '[data-bu-component-add-basket-to-offer]',
    import: () => import('@/components/templates/modals/add-basket-to-offers/app/index.js')
  },
  {
    selector: '[data-bu-my-dashboard]',
    import: () => import('@/apps/myDashboard/index.js')
  },
  {
    selector: '[data-bu-my-data]',
    import: () => import('@/apps/myData/index.js')
  },
  {
    selector: '[data-bu-my-offers]',
    import: () => import('@/apps/myOffers/index.js')
  },
  {
    selector: '[data-bu-my-orders]',
    import: () => import('@/apps/myOrders/index.js')
  },
  {
    selector: '[data-bu-my-services]',
    import: () => import('@/apps/myServices/index.js')
  },
  {
    selector: '[data-bu-my-wishlist]',
    import: () => import('@/apps/myWishlist/index.js')
  },
  {
    selector: '[data-bu-registration], [data-bu-template-registration-password]',
    import: () => import('@/apps/registration/index.js')
  },
  {
    selector: '[data-bu-product-category]',
    import: () => import('@/apps/productCategory/index.js')
  },
  {
    selector: '[data-bu-product-listing]',
    import: () => import('@/apps/productListing/index.js')
  },
  {
    selector: '[data-bu-wishlist]',
    import: () => import('@/apps/wishlist/index.js')
  },
  {
    selector: '[data-bu-search]',
    import: () => import('@/apps/search/index.js')
  },
  {
    selector: '[data-bu-cases]',
    import: () => import('@/apps/cases/index.js')
  },
  {
    selector: '[data-bu-article-detail]',
    import: () => import('@/apps/article-detail/index.js')
  },
  {
    selector: '[data-bu-quickorder]',
    import: () => import('@/apps/quickorder/index.js')
  },
  {
    selector: '[data-bu-close-to-you]',
    import: () => import('@/apps/close-to-you/index.js')
  },
  {
    selector: '[data-bu-basket]',
    import: () => import('@/apps/basket/index.js')
  },
  {
    selector: '[data-bu-checkout]',
    import: () => import('@/apps/checkout/index.js')
  },
  {
    selector: '[data-bu-comparison]',
    import: () => import('@/apps/comparison/index.js')
  },
  {
    selector: '[data-bu-status-card]',
    import: () => import('@/components/templates/status-card/index.js')
  },
  {
    selector: '[data-bu-welcome-message]',
    import: () => import('@/components/templates/welcome-message/index.js')
  },
  {
    selector: '[data-bu-contact-card]',
    import: () => import('@/components/templates/contact-card/index.js')
  }
];

/**
 * Initialize a single Vue component
 *
 * @param {Object} component - Component configuration object
 * @returns {Promise<void>}
 */
const initComponent = async component => {
  const elems = document.querySelectorAll(component.selector);
  if (elems.length) {
    try {
      const { default: mount } = await component.import();
      for (const elem of elems) {
        await mount(elem, elem.dataset);
      }
    } catch (error) {
      console.error(`Failed to initialize component:`, error);
    }
  }
};

/**
 * Initialize all Bürkert Vue components
 */
const initAllBuVue = async () => {
  // initialize icons + ui-lib twig javascript
  await initTwig();

  // initialize our javascript
  // @ts-expect-error declared in vite
  const modules = import.meta.glob(
    './components/{atoms,molecules,organisms,templates}/*/javascripts/index.js'
  );
  // @ts-expect-error declared in vite
  await Promise.all(Object.values(modules).map(module => module()));

  // Initialize store
  await StoreService.initializeWith({
    api: true,
    session: true
  });

  // Initialize all other components
  for (const component of MOUNTQUEUE) {
    await initComponent(component);
  }
};

export default initAllBuVue;
