// Use what-input to track input method
import 'what-input';
// Vue
import { store } from '@/store';

// @ts-expect-error declared by vite
import initAllBuVue from '@';
import triggerCustom from './events/triggerCustom';
import rot13 from '@/javascripts/helpers/rot13';

// mobile device detection
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  // true for mobile device
  const html = document.getElementsByTagName('html')[0];
  if (html.classList) html.classList.add('mobile-device');
}

// all links with target="_blank" attribute should hav an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
document.querySelectorAll('[target="_blank"]').forEach(link => {
  link.setAttribute('rel', 'noopener');
});

// global BUERKERT object
window.BUERKERT = window.BUERKERT || {};

window.BUERKERT.reinitModules = () => {
  triggerCustom(document, 'buerkert.reinitModules');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.reinitVueModules = () => {
  triggerCustom(document, 'buerkert.reinitVueModules');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.reinitImages = () => {
  triggerCustom(document, 'buerkert.reinitImages');
};

/**
 * Starts and ends the basket
 */
// @ts-expect-error declared by siso externally
window.BUERKERT.updateBasket = (update: boolean) => {
  triggerCustom(document, 'buerkert.updateBasket', { update });
  store.dispatch('session/setSessionData');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.closeModal = modalId => {
  const $modal = document.getElementById(modalId);
  triggerCustom($modal, 'modal.close');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.openModal = modalId => {
  const $modal = document.getElementById(modalId);
  triggerCustom($modal, 'modal.open');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.toggleModal = modalId => {
  const $modal = document.getElementById(modalId);
  triggerCustom($modal, 'modal.toggle');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.lockModal = modalId => {
  const $modal = document.getElementById(modalId);
  triggerCustom($modal, 'modal.lock');
};

// @ts-expect-error declared by siso externally
window.BUERKERT.unlockModal = modalId => {
  const $modal = document.getElementById(modalId);
  triggerCustom($modal, 'modal.unlock');
};

/**
 * Dispatch a loadable button event
 */
const dispatchBtnEvent = (elem: HTMLElement | null, evName: string): void => {
  let $btn = elem;

  if (typeof $btn === 'string') {
    $btn = document.getElementById($btn);
  }

  if ($btn && $btn.classList.contains('a-button-loadable__button')) {
    $btn = $btn.closest('.a-button-loadable');
  }

  if (!$btn || !$btn.classList.contains('a-button-loadable')) {
    throw new Error('Unknown elem: ');
  }

  /* Sync Groups: All .a-button-loadable with the same data-sync-group
   * attribute are synchronized.
   */
  const { syncGroup } = $btn.dataset;
  const $triggers = syncGroup
    ? document.querySelectorAll(`.a-button-loadable[data-sync-group="${syncGroup}"]`)
    : [$btn];
  $triggers.forEach($trigger => triggerCustom($trigger, `btn.${evName}`));
};

// @ts-expect-error declared by siso externally
window.BUERKERT.hideButton = elem => dispatchBtnEvent(elem, 'hide');
// @ts-expect-error declared by siso externally
window.BUERKERT.disableButton = elem => dispatchBtnEvent(elem, 'disable');
// @ts-expect-error declared by siso externally
window.BUERKERT.enableButton = elem => dispatchBtnEvent(elem, 'enable');
// @ts-expect-error declared by siso externally
window.BUERKERT.loadingButton = elem => dispatchBtnEvent(elem, 'loading');
// @ts-expect-error declared by siso externally
window.BUERKERT.successButton = elem => dispatchBtnEvent(elem, 'success');
// @ts-expect-error declared by siso externally
window.BUERKERT.errorButton = elem => dispatchBtnEvent(elem, 'error');

/* product comparison --> Vuex store synchronization
 * See src/apps/productListing/javascripts/connectComparison.js
 */
// @ts-expect-error declared by siso externally
window.BUERKERT.comparison = window.BUERKERT.comparison || {};

// @ts-expect-error declared by siso externally
window.BUERKERT.comparison.initalCheckboxCheck = async (skus = []) => {
  const { initialCheckboxCheck } = await import(
    '@/apps/productListing/javascripts/connectComparison'
  );
  initialCheckboxCheck(skus);
};

// @ts-expect-error declared by siso externally
window.BUERKERT.comparison.resetAll = async () => {
  const { resetAll } = await import('@/apps/productListing/javascripts/connectComparison');
  resetAll();
};

// @ts-expect-error declared by siso externally
window.BUERKERT.comparison.resetProduct = async (sku: string) => {
  const { resetProduct } = await import('@/apps/productListing/javascripts/connectComparison');
  resetProduct(sku);
};

/**
 * Initialize Bürkert Vue instances
 * after DOM content is loaded to prevent racing conditions
 */
if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', initAllBuVue);
} else {
  initAllBuVue();
}

window.addEventListener('productlisting-mounted', () => {
  initAllBuVue();
});

/**
 * All mailto links can come as rot13 encoded. If they are, we decode the email text first,
 * and onclick, decode the href aswell.
 */
const handleMailEncoding = () => {
  const mailToLinks: HTMLAnchorElement[] = Array.from(
    document.querySelectorAll(`a[href^="${rot13('mailto:')}"]`)
  );

  mailToLinks.forEach(link => {
    if (link.dataset.decoded !== 'true') {
      if (link.innerHTML.includes('@')) {
        const firstChild = link.firstChild as HTMLElement | undefined;

        if (firstChild && link.childElementCount > 0) {
          firstChild.innerHTML = rot13(firstChild.innerHTML);
        } else {
          link.innerHTML = rot13(link.innerHTML);
        }

        link.dataset.decoded = 'true';
      }

      link.addEventListener('click', () => {
        link.href = rot13(link.href);
      });
    }
  });
};
handleMailEncoding();
